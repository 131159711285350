// import React, { useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Grid';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import { Play } from 'lucide-react';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { authState, currentDashboardState } from '../../../../../../atoms';

// const Hero = () => {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [openVideo, setOpenVideo] = useState(false);

//   const auth = useRecoilValue(authState);
//   const currentDashboard = useRecoilValue(currentDashboardState);

//   const handleOpenVideo = () => setOpenVideo(true);
//   const handleCloseVideo = () => setOpenVideo(false);

//   const handleAction = () => {
//     if (auth.isAuthenticated) {
//       navigate(currentDashboard || '/dashboard');
//     } else {
//       navigate('/register');
//     }
//   };

//   const GridItemHeadlineBlock = () => (
//     <Box>
//       <Typography
//         variant="h3"
//         align="center"
//         gutterBottom
//         sx={{
//           fontWeight: 900,
//           fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
//         }}
//       >
//         Specialized AI Tools for Your Business
//       </Typography>
//       <Typography
//         variant="h6"
//         component="p"
//         color="text.secondary"
//         align="center"
//         sx={{
//           fontWeight: 400,
//           mb: 4,
//           fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
//         }}
//       >
//         Empower your company with our suite of AI tools designed specifically
//         for modern businesses. Take control of all your digital activities
//         without breaking the bank!
//       </Typography>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: { xs: 'column', sm: 'row' },
//           alignItems: 'center',
//           justifyContent: 'center',
//           gap: { xs: 2, sm: 3 },
//           mb: 4,
//         }}
//       >
//         <Button
//           variant="contained"
//           color="primary"
//           size="large"
//           fullWidth={isMobile}
//           onClick={handleAction}
//           sx={{
//             px: 4,
//             py: 1.5,
//             fontSize: { xs: '1rem', sm: '1.1rem' },
//             fontWeight: 600,
//           }}
//         >
//           {auth.isAuthenticated ? 'Go to Dashboard' : 'Register Now'}
//         </Button>
//         <Button
//           variant="outlined"
//           color="primary"
//           size="large"
//           fullWidth={isMobile}
//           startIcon={<Play size={20} />}
//           onClick={handleOpenVideo}
//           sx={{
//             px: 4,
//             py: 1.5,
//             fontSize: { xs: '1rem', sm: '1.1rem' },
//             fontWeight: 600,
//           }}
//         >
//           Watch Video
//         </Button>
//       </Box>
//       {!auth.isAuthenticated && (
//         <Typography
//           variant="body1"
//           align="center"
//           sx={{
//             fontWeight: 500,
//             color: theme.palette.success.main,
//             fontSize: { xs: '0.9rem', sm: '1rem' },
//           }}
//         >
//           Register now and get 10,000 credits free to experience the power of
//           our AI tools!
//         </Typography>
//       )}
//     </Box>
//   );

//   return (
//     <Box sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
//       <Grid container spacing={4}>
//         <Grid item xs={12}>
//           <Box
//             width="100%"
//             height="100%"
//             display="flex"
//             justifyContent="center"
//           >
//             <GridItemHeadlineBlock />
//           </Box>
//         </Grid>
//       </Grid>
//       <Dialog
//         open={openVideo}
//         onClose={handleCloseVideo}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogContent sx={{ p: 0, position: 'relative' }}>
//           <IconButton
//             aria-label="close"
//             onClick={handleCloseVideo}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: 'white',
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//           <iframe
//             width="100%"
//             height="500"
//             src="https://www.youtube.com/embed/ZeoToUSI8g8?autoplay=1"
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           ></iframe>
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// };

// export default Hero;

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Play } from 'lucide-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { authState, currentDashboardState } from '../../../../../../atoms';

const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openVideo, setOpenVideo] = useState(false);

  const auth = useRecoilValue(authState);
  const currentDashboard = useRecoilValue(currentDashboardState);

  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);

  const handleAction = () => {
    if (auth.isAuthenticated) {
      navigate(currentDashboard || '/dashboard');
    } else {
      navigate('/register');
    }
  };

  return (
    <Box component="section" sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 900,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Specialized AI Tools for Your Business
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            color="text.secondary"
            sx={{
              fontWeight: 400,
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
            }}
          >
            Empower your company with our suite of AI tools designed
            specifically for modern businesses. Transform your marketing,
            reporting, and customer experience!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: { xs: 2, sm: 3 },
              mb: 4,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMobile}
              onClick={handleAction}
              sx={{
                px: 4,
                py: 1.5,
                fontSize: { xs: '1rem', sm: '1.1rem' },
                fontWeight: 600,
              }}
            >
              {auth.isAuthenticated ? 'Go to Dashboard' : 'Register Now'}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={isMobile}
              startIcon={<Play size={20} />}
              onClick={handleOpenVideo}
              sx={{
                px: 4,
                py: 1.5,
                fontSize: { xs: '1rem', sm: '1.1rem' },
                fontWeight: 600,
              }}
            >
              Watch Video
            </Button>
          </Box>
          {!auth.isAuthenticated && (
            <Box
              sx={{
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{
                  fontWeight: 500,
                  color: 'text.primary',
                }}
              >
                <strong>Limited Time Offer:</strong> Register now and get 10,000
                credits free! Use your referral code for even more benefits.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="https://res.cloudinary.com/dxdpahm3o/image/upload/v1726715864/Wisdom-Scribe/Landings/happy-businessman.jpg"
            alt="AI-powered business tools"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2,
              boxShadow: 3,
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openVideo}
        onClose={handleCloseVideo}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ p: 0, position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseVideo}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/ZeoToUSI8g8?autoplay=1"
            title="Wisdom Scribe: AI Tools for Your Business"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Hero;
